import React from "react"

import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  List,
  ListItem,
  VideoPlayer,
  CTA,
} from "@/components"

export default function BeginnerPath() {
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO title="Beginner Path" description="Sonora Prep" noindex={true} />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem"></Text>

          <Heading
            level="1"
            mb="1rem"
            mt="1rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            Just getting started on guitar?
          </Heading>
          <Text mb="1rem" mt="1rem" px={["2rem", "2rem", "2rem", "2rem"]}>
            Check out our prep program
          </Text>
        </Box>
        <Width
          display="flex"
          flexDirection={["column-reverse", "row"]}
          pt={["1.2rem", null, null, "4.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", 6 / 12]}
            textAlign="left"
            mb={["6rem", null, null, "0"]}
            pr={["0", "2rem"]}
          >
            <Box display="flex" flexDirection={["column-reverse", "column"]}>
              <Box mt={["3rem", null, null, "0rem"]}>
                <Text mb="2.3rem">
                  It sounds like you rated yourself as a beginner... Awesome!
                </Text>
                <Text pb="1rem">
                  Check out our <strong>6-week beginner program</strong>!
                </Text>
                <List bullets="checkmark">
                  <ListItem>
                    Follow a well structured path from beginner to intermediate
                  </ListItem>
                  <ListItem>
                    Learn from our world-class mentor staff as you
                    develop your foundation and grow as a musician.
                  </ListItem>
                  <ListItem>
                    Weekly practice plans tell you exactly what to do
                    every step of the way.
                  </ListItem>
                </List>
                <Box mb={["2rem", null, null, "0"]}>
                  <CTA variant="special" to="/prep" mb="1.2rem" mt="5rem">
                    Learn More
                  </CTA>
                </Box>
                <Text mt={"2rem"}>
                  Unsure if you're a beginner?{" "}
                  <a href="/schedule">Speak with admissions</a>
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            width={["100%", 6 / 12]}
            textAlign="left"
            ml={["0", "auto"]}
            pb={["0rem", null, null, "0.9rem"]}
          >
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              p="0.6rem"
              css={{ cursor: "pointer" }}
            >
              <VideoPlayer
                width="auto"
                maxWidth="auto"
                url="https://vimeo.com/584602101"
              />
            </Box>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
